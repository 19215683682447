import React from "react";

const Accordion = ({id, children}) => {

    return (
        <div className="accordion accordion-flush" id={id}>
            {children}
        </div>
    );

}

export default Accordion;
