import React from "react";
import H2 from "../atoms/H2";
import classNames from "classnames";

const AccordionHeader = ({id, counter, title, show}) => {

    return (
        <div className="accordion-header" id={`heading-${id}`}>
            <div className={classNames('accordion-button', {'collapsed': !show})} type="button" data-bs-toggle="collapse"
                       data-bs-target={`#collapse-${id}`} aria-expanded="true" aria-controls={`collapse-${id}`}>
                <div className="row d-flex flex-grow-1 align-items-center">
                    <div className="col-5">
                        <H2 className="text-tia-maria flex-grow-1">{counter}</H2>
                    </div>
                    <div className="col-6">
                        <h3 className="text-uppercase flex-grow-1 text-black">{title}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccordionHeader;
