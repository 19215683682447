import React, {useState} from "react";
import MainPage from './components/pages/Main';
import './scss/main.scss';
import 'bootstrap/dist/js/bootstrap';
import {SmoothProvider} from 'react-smooth-scrolling';
import H1 from "./components/atoms/H1";
import qrcode from "./assets/svg/QR_Nota.svg";
import contact1 from "./assets/img/Kontakt_stlp.png";

function App() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <SmoothProvider className="vh-100">
                <MainPage onModal={() => setIsOpen(true)}/>
            </SmoothProvider>
            {isOpen && <div className="modal show d-block" tabIndex="-1" id="menu" aria-labelledby="menu">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-body bg-botticelli">
                            <div className="row mt-6">
                                <div className="col-5 offset-3">
                                    <nav className="navbar">
                                        <ul className="navbar-nav">
                                            <li className="nav-item">
                                                <H1><a href="#intro" className="text-decoration-none text-black" onClick={() => setIsOpen(false)}>Predstavenie</a></H1>
                                            </li>
                                            <li className="nav-item">
                                                <H1><a href="#services" className="text-decoration-none text-black" onClick={() => setIsOpen(false)}>Právne služby</a></H1>
                                            </li>
                                            <li className="nav-item">
                                                <H1><a href="#office" className="text-decoration-none text-black" onClick={() => setIsOpen(false)}>Kancelária</a></H1>
                                            </li>
                                            <li className="nav-item">
                                                <H1><a href="#contact" className="text-decoration-none text-black"  onClick={() => setIsOpen(false)}>Kontakt</a></H1>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="col-1 offset-2 text-end">
                                    <button type="button" className="btn-close mt-5" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsOpen(false)}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2 offset-2">
                                    <img src={qrcode} alt="code" width={142} className="mt-5"/>
                                </div>
                                <div className="col-2">
                                    <div className="ls-n1 mb-3 mt-8">-------</div>
                                    <h4 className="mb-4">JUDr. ján Nota</h4>
                                    <h4 className="mb-4">+421 903 690 189</h4>
                                </div>
                                <div className="col-4">
                                    <img src={contact1} alt="" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default App;
