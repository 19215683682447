import React from 'react';
import H1 from "../atoms/H1";
import AccordionItem from "../organisms/AccordionItem";
import Accordion from "../organisms/Accordion";
import {Link} from 'react-scroll';

import circle from '../../assets/svg/Kruh.svg';
import book from '../../assets/svg/Kniha.svg';
import download from '../../assets/svg/Stiahnut.svg';
import qrcode from '../../assets/svg/QR_Nota.svg';
import logo1 from '../../assets/svg/Logo_1.svg';
import logo2 from '../../assets/svg/Logo_2.svg';

import diary from '../../assets/img/Diar.png';
import contact1 from '../../assets/img/Kontakt_stlp.png';
import contact2 from '../../assets/img/Kontakt_2.png';
import intro from '../../assets/img/Uvod.png';

import HamburgerIcon from "../atoms/HamburgerIcon";
import SignatureLogo from "../atoms/SignatureLogo";

const MainPage = ({onModal}) => {

    function getAccordionItem() {
        return <div className="ms-n2 mt-n3 position-relative">
            <p className="mb-4">
                Advokátska kancelária JUDr. Ján Nota poskytuje<br/>
                komplexné právne služby v oblasti trestného práva
            </p>
            <span className="ls-n1">-------</span>
            <p>
                <strong>
                    Obhajoba v trestnom konaní<br/>
                    Zastupovanie poškodených v trestnom konaní<br/>
                    Vypracovanie podaní, trestných oznámení, právnych rozborov
                </strong>
            </p>
        </div>;
    }

    return (
        <>
            <div className="container">
                <nav className="navbar navbar-expand navbar-light bg-light align-items-end mb-4">
                    <div className="collapse navbar-collapse justify-content-center" id="main-menu">
                        <ul className="navbar-nav align-items-end mt-5">
                            <li className="nav-item">
                                <Link to="intro" className="nav-link mx-3">Predstavenie</Link>
                            </li>
                            <li className="nav-item">
                                <a href="#services" className="nav-link mx-3">Právne služby</a>
                            </li>
                            <div className="d-flex flex-column align-items-center">
                                <img src={logo1} alt="" className="mx-7 mb-3" width="58"/>
                                <img src={logo2} alt="" className="mx-7 mt-1" width="83"/>
                            </div>
                            <li className="nav-item">
                                <a href="#office" className="nav-link mx-3">Kancelária</a>
                            </li>
                            <li className="nav-item">
                                <a href="#contact" className="nav-link mx-3">Kontakt</a>
                            </li>
                        </ul>
                    </div>
                    <HamburgerIcon onClick={onModal}/>
                </nav>
            </div>
            <div className="container" id="intro">
                <div className="row">
                    <H1 className="offset-3 col-6 mt-7 mb-n5 z-index-100">Lorem ipsum dolor sit ame consectr</H1>
                </div>
                <div className="row">
                    <div className="col-1 offset-1 mt-8 pt-6">
                        SCROLUJ
                    </div>
                    <div className="col-5">
                        <img src={intro} alt=""/>
                    </div>
                    <div className="col-3 offset-1 mt-8 pt-6 text-uppercase">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-2 offset-6">
                        <img src={circle} alt="circle" width="182" className="mt-n6 mb-6 spinning"/>
                    </div>
                </div>

                <div className="row mb-9">
                    <div className="col-2 offset-1 mt-5">
                        <div className="ls-n1 mb-3">-------</div>
                        <h5 className="mb-4">Advokát zapísaný v zozname advokátov SAK</h5>
                        <div>
                            <img src={book} alt="" width="31"/>&nbsp;&nbsp;&nbsp;<span
                            className="fw-semibold">7255</span>
                        </div>
                    </div>
                    <div className="col-3 offset-2">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.</p>
                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                            commodo. consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.</p>
                    </div>
                </div>
            </div>
            <div className="container" id="services">
                <div className="row mb-7">
                    <div className="col-2 offset-1 mt-5">
                        PRÁVNE SLUŽBY
                    </div>
                    <div className="col-4 offset-1">
                        <H1>Accusantium doloremque</H1>
                    </div>
                </div>
                <div className="row mb-6">
                    <Accordion id="services">
                        <AccordionItem id="item1" counter="01" title="trestné právo" show={true}>
                            {getAccordionItem()}
                        </AccordionItem>
                        <AccordionItem id="item2" counter="02" title="Občianske právo">
                            {getAccordionItem()}
                        </AccordionItem>
                        <AccordionItem id="item3" counter="03" title="OBCHODNÉ PRÁVO">
                            {getAccordionItem()}
                        </AccordionItem>
                        <AccordionItem id="item4" counter="04" title="PRACOVNÉ PRÁVO">
                            {getAccordionItem()}
                        </AccordionItem>
                        <AccordionItem id="item5" counter="05" title="správne PRÁVO">
                            {getAccordionItem()}
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className="container bg-polar py-6" id="office">
                <div className="row">
                    <div className="col-4 offset-2">
                        <img src={contact1} alt="" className="img-fluid"/>
                    </div>
                    <div className="col-3 offset-1">
                        <img src={diary} alt="" className="img-fluid mt-8"/>
                    </div>
                </div>
                <div className="row mt-n8">
                    <div className="col-2 offset-1 text-uppercase">KANCELÁRIA</div>
                    <div className="col-4 offset-1 mt-n5 mb-7">
                        <H1>Itsedoeiusmod temporincid</H1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-2 offset-1 mt-8">
                        <div className="ls-n1 mb-3">-------</div>
                        <h5 className="mb-4">Poučenie klienta <br/> advokátom</h5>
                        <div>
                            <img src={download} alt="" width="31"/>&nbsp;&nbsp;&nbsp;<span
                            className="fw-semibold">Stiahnuť</span>
                        </div>
                    </div>
                    <div className="col-3 offset-2">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.</p>
                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                            commodo. consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.</p>
                        <p>Ed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>

            <div className="container" id="contact">
                <div className="row mt-9 mb-7">
                    <div className="col-2 offset-1 mt-5 text-uppercase">Kontakt</div>
                    <div className="col-7 offset-1">
                        <H1>Potrebujete poradiť v&nbsp;právnej otázke?</H1>
                    </div>
                </div>

                <div className="row mb-7">
                    <div className="col-2 offset-2">
                        <img src={qrcode} alt="" width="142"/>
                    </div>
                    <div className="col-3 mt-6">
                        <div className="ls-n1 mb-3">-------</div>
                        <h4 className="mb-4">JUDr. ján Nota</h4>
                        <h4 className="mb-4">+421 903 690 189</h4>

                        <button className="border-1 p-3 bg-transparent mb-5">Kontaktovať</button>
                        <div className="ls-n1 mb-3">-------</div>
                        <h4 className="mb-4 fw-semibold">Fakturačné údaje</h4>
                        <h4 className="mb-2">JUDr. ján Nota</h4>
                        <h4 className="mb-4">Veličná 371, 027 54 Veličná</h4>
                        <h4 className="mb-2">IČO 50 150 464</h4>
                        <h4 className="mb-3">DIČ 1082716536</h4>
                    </div>
                    <div className="col-4 mt-6">
                        <img src={contact2} alt="" className="img-fluid"/>
                    </div>
                </div>

            </div>

            <div className="container">
                <footer className="row">
                    <div className="col-12 text-center my-5">
                        <h5 className="d-inline"><strong>2022 &copy; JUDr. Ján Nota, advokát,</strong> všetky práva
                            vyhradené</h5>
                        <img src={logo1} alt="logo" width="58" className="ms-6  me-5"/>
                        <h5 className="d-inline">Spracovanie cookies a ochrany osobných údajov</h5>
                        <span className="mx-3 ls-n1">-----</span> <h5 className="d-inline">made by</h5>
                        <SignatureLogo/>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default MainPage;
