import logo from "../../assets/img/logo.png";
import logoGif from "../../assets/img/MAULL_gif_final.gif";
import React, {useState} from "react";

const SignatureLogo = () => {
    const [activated, setActivated] = useState(false);

    return <span className="signature">
        {activated && <img src={logoGif} alt="logo" height="30"/>}
        {!activated && <img src={logo} alt="logo" height="30" onMouseEnter={() => setActivated(true)}/>}
    </span>
}

export default SignatureLogo;
