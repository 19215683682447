
const HamburgerIcon = ({onClick}) => {
    return (
        <div className="hamburger-icon p-2" role="button" onClick={onClick}>
            <div/>
            <div/>
        </div>
    );
}

export default HamburgerIcon;
