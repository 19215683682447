import React from "react";
import AccordionHeader from "./AccordionHeader";
import AccordionBody from "./AccordionBody";

const AccordionItem = ({id, counter, title, children, show}) => {

    return <div className="accordion-item">
        <AccordionHeader id={id} counter={counter} title={title} show={show}/>
        <AccordionBody id={id} show={show}>
            {children}
        </AccordionBody>
    </div>
}

export default AccordionItem;
