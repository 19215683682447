import React from "react";
import classNames from "classnames";
const AccordionBody = ({id, children, show}) => {
    return (
        <div id={`collapse-${id}`} className={classNames('accordion-collapse collapse', {'show': show})} aria-labelledby={`heading-${id}`} data-bs-parent="#accordionExample">
            <div className="accordion-body">
                <div className="row">
                    <div className="col-5 offset-5">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccordionBody;
